@use '../pallet';
@use '../components/text-field';

#company-details-image {
	background: url('../../images/company-deatils/company-details.jpg') no-repeat
		100% 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100vh;
}

#company-details-image-container {
	margin-top: 25vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 1.041vw;

	.typography-h1 {
		font-size: 70px !important;
	}

	.white-line {
		width: 90%;
		border: none;
		border-top: 1px solid pallet.$border-color;
		margin-top: 4%;
		margin-bottom: 4%;
		font-size: 1px;
	}

	.default-white-button {
		width: 13vw;
		border: none !important;
	}
}

#company-details-container {
	width: 80%;
	margin: 50px auto;

	@media (max-width: 900px) {
		width: 95%;
	}

	#company-details-content {
		.circular-progress {
			margin-top: 50px !important;
		}
		#directors-radio-group {
			margin: 3% 0 1%;
			width: 50%;

			@media (max-width: 900px) {
				width: 100%;
			}

			svg,
			span {
				padding: 0 10px 0 0;
			}

			label {
				padding: 2% 0;
			}

			.director {
				border-bottom: 1px solid pallet.$gray-500;
				margin-left: 0 !important;
			}
		}

		.typography-black-header {
			margin-bottom: 10px;
		}

		.typography-black-header.director {
			margin-top: 20px;
		}

		.typography-black-subheader {
			padding-bottom: 50px;
		}

		.form-content {
			margin-top: 5px;
			margin-bottom: 20px;
		}

		.default-select {
			@extend .default-text-field;
		}

		.enter-details-manually {
			margin-top: 50px;
			font-size: 0.937vw !important;
			line-height: 1.6vw;
			cursor: pointer;

			@media (max-width: 1536px) {
				font-size: 1.1vw;
				line-height: 1.5vw;
			}

			&:hover {
				box-shadow: inset 0 -1px 0 0 pallet.$border-color;
			}
		}

		img {
			width: 20px;
			height: 20px;
			margin-right: 15px;
		}

		.MuiFormControlLabel-label {
			font-weight: pallet.$medium;
			line-height: 1.2vw;
			font-size: 1.04vw !important;

			@media (max-width: 1536px) {
				font-size: 0.972vw !important;
			}
		}

		.company-details-checkboxes {
			margin: 25px 0;

			.MuiFormControlLabel-label {
				font-size: 1.1459vw;
				font-weight: 500;
				color: #000000;

				@media (max-width: 1536px) {
					font-size: 1.25vw;
				}
			}
		}

		.MuiCheckbox-root.Mui-checked {
			color: #0160fd;
		}

		.MuiCheckbox-root {
			color: #abaeb7;
		}

		.buttons-row-div {
			display: flex;
			flex-direction: row;
			width: 30%;
			gap: 1vw;
		}
	}
}

#company-details-continue-button {
	margin-top: 50px;
	width: 13vw;
	font-size: 0.83vw !important;

	@media (max-width: 1536px) {
		font-size: 0.972vw;
	}
}

#director-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border: 1px solid black;
	padding: 5%;
	width: 39.791vw;

	@media (max-width: 1536px) {
		width: 46.25vw;
	}

	.director-modal-title {
		font-weight: bold;
		font-size: 2.083vw;
		line-height: 2.2vw;
		color: black;
		margin-bottom: 3%;

		@media (max-width: 1536px) {
			font-size: 2.43vw;
			line-height: 2.5vw;
		}
	}

	.director-modal-subtitle {
		font-size: 0.937vw;
		line-height: 1.1vw;
		color: pallet.$gray-400;
		margin-bottom: 8%;

		@media (max-width: 1536px) {
			font-size: 0.902vw;
			line-height: 1vw;
		}
	}

	#director-modal-form {
		.MuiFormControl-root {
			margin-bottom: 30px;
		}
	}

	button {
		margin-top: 20px !important;
	}
}
